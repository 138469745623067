/* frontend/chatbot/src/components/UpdatePatientInfo.css */
.update-patient-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
}

.update-patient-info {
    width: 300px;
    background-color: #f8f9fa;
    border-left: 1px solid #ced4da;
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
}

.update-patient-info-container.open .update-patient-info {
    transform: translateX(0);
}

.toggle-button {
    position: absolute;
    right: -100px;
    top: 0;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    background-color: #007bff;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
}

.toggle-button:hover {
    background-color: #0056b3;
}

.patient-info-panel {
    margin-top: 20px;
}

.patient-info-panel h3 {
    margin-bottom: 10px;
}

.default-options {
    margin-bottom: 10px;
}

.default-options label {
    display: block;
    margin-bottom: 5px;
}

.default-options input {
    margin-right: 10px;
}

.update-patient-info textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 1rem;
}

.update-patient-info button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    background-color: #007bff;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
}

.update-patient-info button:hover {
    background-color: #0056b3;
}
