/* frontend/chatbot/src/App.css */
.App {
    text-align: center;
    background-color: #f8f9fa;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #212529;
}

.App-header {
    font-size: 2rem;
    margin-bottom: 20px;
}

.main-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 60%;
    padding: 0 20px;
}

.chat-container {
    flex: 1;
    margin-right: 20px;
}


.messages {
    border: 1px solid #ced4da;
    padding: 10px;
    height: 400px;
    overflow-y: scroll;
    width: 100%;
    margin-bottom: 20px;
    word-wrap: break-word; /* Ensure long words wrap correctly */
}

.message {
    display: flex;
    align-items: flex-end;
    margin: 10px 0;
}

.message.user {
    justify-content: flex-end;
}

.message.bot {
    justify-content: flex-start;
}

.message .bubble {
    max-width: 70%;
    min-width: 50px;
    padding: 10px;
    border-radius: 20px;
    position: relative;
    word-break: break-word;
    font-size: 1rem;
    display: inline-block;
}

.message.user .bubble {
    background-color: #007AFF;
    color: white;
}

.message.bot .bubble {
    background-color: #F5F5F5;
    color: black;
}

.message .timestamp {
    font-size: 0.8rem;
    color: grey;
    position: absolute;
    bottom: -20px;
}

.message.user .timestamp {
    right: 0;
}

.message.bot .timestamp {
    left: 0;
}

.message img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 10px;
}

input[type="text"] {
    width: calc(100% - 80px);
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 1rem;
}

button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    background-color: #007bff;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
